<div
  class="fixed inset-0 z-[9999] flex h-screen w-screen flex-col items-center justify-center bg-black/75"
>
  <img
    class="h-24 w-24 animate-pulse object-cover"
    src="assets/images/logo-liv.webp"
    alt=""
  />

  <div class="mt-6 text-center">
    <h3 *ngIf="title" class="text-xl font-bold text-neutral-hight-light">
      {{ title }}
    </h3>
    <p class="text-base text-neutral-hight-light">Aguarde um momento.</p>
  </div>
</div>
